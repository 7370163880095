<template>
  <q-tr
      :props="data"
      :class="color"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
      {{ data.rowIndex + 1 }}
    </q-td>

    <q-td
        key="image"
        :props="data"
    >
      <q-card style="min-width: 270px;width: 100%;" :class="color" v-if="data.row?._embedded?.productOffer">
        <q-img
            :src="data.row._embedded?.productOffer?.image || appOptions.fallbackImage"
            style="max-height: 190px;"
            @error="onImageLoadFailure">
          <div class="absolute-bottom text-subtitle2">
            {{ data.row._embedded?.productOffer?.name + (data.row.status ? '(' + (data.row?.status) + ')' : '') }}
          </div>

          <q-chip class="absolute-top-right text-weight-bold cursor-pointer" :color="chipColor" clickable
                  @click="handleShowInfo(data.row._embedded?.productOffer)">

            {{ data.row._embedded?.productOffer?.type }}
            <q-tooltip>
              {{ 'Click to see' }}
            </q-tooltip>
          </q-chip>
        </q-img>
        <!--        <div>-->
        <!--          <img-->
        <!--              :src="data.row._embedded?.productOffer?.image || appOptions.fallbackImage"-->
        <!--              style="height: 50%; width: 50%; object-fit: contain;"-->
        <!--              @error="onImageLoadFailure"-->
        <!--          >-->
        <!--          <q-chip class="absolute-top-right text-weight-bold cursor-pointer" :color="chipColor" clickable-->
        <!--                  @click="handleShowInfo(data.row._embedded?.productOffer)">-->

        <!--            {{ data.row._embedded?.productOffer?.type }}-->
        <!--            <q-tooltip>-->
        <!--              {{ 'Click to see' }}-->
        <!--            </q-tooltip>-->
        <!--          </q-chip>-->
        <!--        </div>-->

        <q-card-section v-if="data.row.errors" align="center">
          <q-chip square icon="error_outline" color="red" text-color="white">{{ $t(`Attention needed`) }}</q-chip>

          <q-tooltip class="q-px-sm text-left">
            <ul class="q-ma-none q-pl-md" style="max-width: 350px">
              <li class="text-caption text-weight-light" v-for="error in data.row.errors">
                {{ error }}
              </li>
            </ul>
          </q-tooltip>
        </q-card-section>
      </q-card>


      <br>

      <q-btn
          v-if="data.row._embedded?.productOffer?.type === 'bundle'"
          color="accent"
          text-color="white"
          unelevated
          no-caps
          :icon="icons"
          :label="label"
          style="min-width: 50%;"
          @click="handleBundleClick"
      />


    </q-td>

    <q-td
        key="name"
        :props="data"
        style="max-width: 150px; word-wrap: break-word; white-space: normal !important; width: 25%"
    >
      <div class="text-subtitle2 q-my-sm">
        {{ name }}
        <q-tooltip>
          {{ 'Name' }}
        </q-tooltip>
      </div>

      <span
          v-if="!data.row._embedded?.productOffer?.items || data.row._embedded?.productOffer?.items.length === 0 || !data.row._embedded?.productOffer?.items[0]">
        <span v-if="data.row.eav && data.row.eav['storage-item']">
          <strong> {{ data.row.raw.sku }}</strong><br/>
          {{ 'ID: ' + data.row.raw._embedded.acceptanceItem.id }} <br/>
          {{ 'TN: ' + data.row.raw._embedded.acceptanceItem.trackingNumber }} <br/>
          {{ 'QR: ' + data.row.raw._embedded.acceptanceItem.quantityReal }}
        </span>
        <span v-else>
          <strong>-</strong>
        </span>
      </span>

      <div v-else>
        <items-table
            :items="data.row._embedded?.productOffer?.items"
            :offer="data.row._embedded?.productOffer"
            style="max-width: 250px"
            show-details
        />
      </div>


    </q-td>

    <q-td
        key="reserves"
        :props="data"
        style="width: 20%"
    >
      <div class="clickable" @dblclick="handleReserveClick(data.row)">
        <strong style="font-size: 200%" v-if="data.row._embedded?.productOffer?.type !== 'bundle'">
          {{ `${quantityReserved}/${data.row.count}` }}
        </strong>

        <strong v-else style="font-size: 200%">
          <span> {{ data.row.count }}</span>
        </strong>
      </div>
    </q-td>

    <q-td
        key="total"
        :props="data"
        style="width: 20%"
    >
      <div>

        <editable-input-field
            label="Price"
            inputLabel="Price"
            :value="data.row.price"
            :disable="disabledDelete"
            :update-function="newValue => handleNumberUpdate('price' , newValue)"
            :rules="[
                (val) => /^[0-9]\d*$/.test(val)
              ]"
        ></editable-input-field>
      </div>

      <br>

      <div>

        <editable-input-field
            label="Quantity"
            inputLabel="Quantity"
            :value="data.row.count"
            :disable="disabledDelete"
            :update-function="newValue => handleNumberUpdate('count' , newValue)"
            :rules="[
              (val) => /^[0-9]\d*$/.test(val)
            ]"
        ></editable-input-field>
      </div>

      <br>

      <div>
        <span> <strong>{{ 'Total: ' + total }}</strong></span>
      </div>

    </q-td>

    <q-td
        v-if="isAmazon"
        key="amazon"
        :props="data"
        style="min-width: 200px;"
    >
      <div>
        <span
            v-if="data.row?._embedded?.productOffer?.eav && data.row?._embedded?.productOffer?.eav['integrations-amazon-offer-asin']">
          {{
            'ASIN: ' + ((data.row._embedded?.productOffer?.eav['integrations-amazon-offer-asin'] || {})[marketplace] || '-')
          }}
        </span>
      </div>

      <br>
      <div>
        <q-select
            v-model="selectedOption"
            :options="optionsArray"
            option-value="key"
            option-label="value"
            label="SKU"
            :emit-value="true"
            menu-cover
            ref="mySelect"
            close-popup-on-select
            use-input
            fill-input
            hide-selected
            hide-dropdown-icon
            clearable
            new-value-mode="add"
        >
          <template v-slot:no-option>
            <q-item clickable @click="addOptionDialog = true">
              <q-item-section>Add Option</q-item-section>
              <q-item-section side>
                <q-icon name="add" color="primary"/>
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:option="scope">
            <q-item :key="scope" v-close-popup clickable @click="handleClick(scope)" class="cursor-pointer">
              <q-item-section>
                {{ scope.label }}
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:after-options>
            <q-item clickable @click="addOptionDialog = true">
              <q-item-section>Add Option</q-item-section>
              <q-item-section side>
                <q-icon name="add" color="primary"/>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        <q-dialog v-model="addOptionDialog" persistent>
          <q-card>
            <q-card-section>
              <q-input v-model="newOption.label" label="Label"/>
            </q-card-section>
            <q-card-actions align="right">
              <q-btn label="Cancel" @click="addOptionDialog = false"/>
              <q-btn label="Add" color="primary" :disable="!newOption.label" @click="addNewOption"/>
            </q-card-actions>
          </q-card>
        </q-dialog>

        <!--        <span>-->
        <!--        <strong>{{ 'SKU: ' + sku}}</strong>-->
        <!--        </span>-->
        <!--        <q-popup-edit v-model="sku" auto-save v-slot="scope2" style="align-content: center" :disable="!sourceId && !key" @close="handleSkuSubmit">-->
        <!--            <q-input type="text" v-model="scope2.value" dense autofocus @update:model-value="updateOfferSku('eav', $event)" @keydown.enter="handleSkuSubmit"/>-->
        <!--        </q-popup-edit>-->

        <!--        <q-tooltip v-if="sourceId || key">-->
        <!--            {{'Click to edit SKU'}}-->
        <!--        </q-tooltip>-->
      </div>
    </q-td>

    <q-td
        key="after"
        ref="eavModal"
    >
      <q-btn-dropdown
          class="q-ml-sm"
          size="lg"
          flat round dense
          no-icon
          dropdown-icon="more_vert"
      >
        <q-list>
          <q-item clickable v-close-popup :disable="disableServices">
            <q-item-section @click="toggleSupplies">
              <q-item-label>
                {{ 'Add supplies to Order Product' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup :disable="instructionsDisabled">
            <q-item-section @click="executeInstructions(data.row)">
              <q-item-label>
                {{ 'Execute Instructions' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup :disable="disabledDelete">
            <q-item-section @click="handleDuplicate">
              <q-item-label>
                {{ 'Duplicate' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup>
            <q-item-section @click="onClick(data , this.$refs.eavModal)">
              <q-item-label>
                {{ 'EAV' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup>
            <q-item-section @click="handleClickWhere">
              <q-item-label>
                {{ 'Where?' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup :disable="disabledDelete">
            <q-item-section @click="openCofirmation">
              <q-item-label>
                {{ 'Delete' }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

    </q-td>
    <eav-modal ref="eavModal">
      <form-builder :schema="schema2"/>
    </eav-modal>
  </q-tr>
  <q-tr v-if="bundleDropDown">
    <q-td
        key="number"
        colspan="6"
    >
      <q-table
          class="sticky-header-table q-mt-md"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="itemsForTable"
          :columns="columns"
          v-model:pagination="pagination"
          hide-header
          hide-bottom
          :loading="isLoading"
          virtual-scroll
          binary-state-sort
      >
        <template v-slot:body="props">
          <order-product-bundle-row
              v-if="props.row.count > 0"
              :data="props"
              :disabled="disabled"
              :disabledDelete="disabledDelete"
              :reserves="reserves"
              :options="{
              reserve: getReserve(props.row),
              warehouse: warehouse,
              minPrice: 0,
              minAmount: order && order.type === 'return' ? 0 : 1,
              noColor: !!order && (!order.id || order.type === 'return'),
            }"
              @open="handleShowInfo"
              @open-reserve="handleReserve"
              @change="handleBundleNumberUpdate"
              @delete="deleteBundleRow"
          />
        </template>
      </q-table>
    </q-td>
  </q-tr>

  <q-dialog v-model="areYouSure">
    <q-card style="max-width: 30vw">
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
          {{ $t('Are You Sure?') }}
        </div>

        <q-space/>

        <q-btn v-close-popup icon="close" flat round dense/>
      </q-card-section>
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <q-card-section class="text-center">

          <q-btn
              textarea="center"
              color="negative"
              class="q-mr-sm"
              text-color="white"
              :label="$t('Delete product')"
              @click="deleteRow"
          />

          <q-btn
              textarea="center"
              :label="$t('Cancel')"
              @click="closeConfirmation"
          />
        </q-card-section>
      </q-card-section>
    </q-card>
  </q-dialog>
  <order-product-supplies-modal ref="suppliesModal" @on-submit="save"/>

  <order-allocation-modal ref="orderAllocation"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import ItemsTable from '../items-table/ItemsTable.vue'
import EavModal from '../../components/modals/EavModal.vue'
import OrderProductBundleRow from './OrderProductBundleRow.vue'
import OrderProductSuppliesModal from '../../../app/components/modals/OrderProductSuppliesModal.vue'
import Template from '@/apps/app/pages/notifications/Template.vue'
import EditableInputField from '@/apps/app/components/delivery-services/EditableInputField.vue'
import OrderAllocationModal from '@/apps/app/components/modals/OrderAllocationModal.vue'

export default {
  name: 'OrderProductRowNew',
  emits: ['open', 'change', 'delete', 'open-reserve', 'duplicate'],
  components: {
    OrderAllocationModal,
    EditableInputField,
    Template,
    OrderProductSuppliesModal,
    ItemsTable,
    EavModal,
    OrderProductBundleRow
  },
  props: {
    options: {
      type: Object,
      default () {
        return {
          warehouses: [],
          reserve: null,
          warehouse: null,
          minPrice: 0,
          minAmount: 0
        }
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabledDelete: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Object,
      required: true
    },
    bundleProducts: {
      type: Object
    },
    order: {
      type: Object,
      default () {
        return null
      }
    },
    products: {
      type: Array,
      default () {
        return []
      }
    },
    reserves: {
      type: Array,
      default () {
        return []
      }
    },
    instructionsService: {
      type: Object,
      default () {
        return null
      }
    },
    isAmazon: {
      type: Boolean,
      default () {
        return false
      }
    },
    shop: {
      type: Object,
      default () {
        return null
      }
    },
  },
  data () {
    return {
      stateColor: {
        new: 'grey-4',
        picking: 'teal-12',
        cancelled: 'danger',
        confirmed: 'teal'
      },
      quantityReserved: 0,
      bundleDropDown: false,
      pagination: {
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      isLoading: false,
      items: [],
      bundleItems: [],
      itemsForTable: [],
      isBundleReserved: false,
      count: 0,
      price: 0,
      reservesToShow: [],
      areYouSure: false,
      marketplace: '',
      key: '',
      sku: '',
      sourceId: '',
      updatedSKU: null,
      selectedOption: '',
      optionsArray: [],
      addOptionDialog: false,
      newOption: { key: '', label: '' },
    }
  },
  watch: {
    products (newValue) {
      // Increment the component key value when the route changes
      if (this.marketplace) {
        // this.selectedOption = this.data.row.eav['integrations-amazon-offer-sku']
        this.getMsku(this.marketplace)
      }
    },
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'order',
    ]),
    disableServices () {
      if (!this.order || !this.order.id) {
        return true
      }
      return false
    },
    instructionsDisabled () {
      if (this.data.row.eav && this.data.row.eav['storage-assembling-instructions']) {
        return false
      }
      return true
    },
    name () {
      const name = this.data.row._embedded?.productOffer?.name

      if (this.data.row.id) {
        return `${name} (${this.data.row.id})`
      }

      return name
    },
    icons () {
      if (this.bundleDropDown) {
        return 'arrow_drop_up'
      }
      return 'arrow_drop_down'
    },
    schema2 () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.data.row.extId,
                label: this.$t('Ext ID'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
              }
            ]
          }
        ]
      }
    },
    columns () {
      return [
        {
          name: 'number',
          align: 'left'
        },
        {
          name: 'image',
          align: 'left'
        },
        {
          name: 'name',
          align: 'left'
        },
        {
          name: 'reserves',
          align: 'center',
          headerClasses: this.order && this.order.type === 'return'
              ? 'd-none'
              : '',
          classes: this.order && this.order.type === 'return'
              ? 'd-none'
              : '',
        },
        {
          name: 'total',
          align: 'left'
        },
        {
          name: 'after',
          align: 'right'
        }
      ]
    },
    total () {
      return ((this.data.row.price || 0) * (this.data.row.count || 0)).toFixed(2)
    },
    reserve () {
      if (!this.options.reserve) {
        this.resetQuantityReserved()
        return [{
          items: 0,
          id: this.$t('No reserve')
        }]
      }

      this.checkQuantity()

      return this.options.reserve
    },
    chipColor () {
      if (this.data.row?._embedded?.productOffer?.type === 'bundle') {
        return 'accent'
      }
      return 'info'
    },
    label () {
      if (this.bundleDropDown) {
        return 'Hide components'
      }
      return 'Show components'
    },
    color () {
      if (this.data.row?.state === 'return') {
        return 'bg-negative'
      }

      if (this.data.row?._embedded?.productOffer?.type !== 'bundle') {
        if (this.options.noColor) {
          return ''
        }

        if (this.reserve.items >= this.data.row.count || this.quantityReserved >= this.data.row.count) {
          return ''
        }

        return 'bg-warning'
      } else {
        const checkProducts = this.bundleProducts.filter(e => e.eav['products-order-bundle-order-product'] === this.data.row.id)

        let notReserved = false

        for (let i = 0; i < checkProducts.length; i++) {
          let currentProduct = checkProducts[i]
          const reservesToCheck = this.reserves.filter(e => e.offer === currentProduct?._embedded?.productOffer.id)
          if (reservesToCheck.length === 0) {
            notReserved = true
            continue
          }

          if (reservesToCheck[0].items < currentProduct.count) {
            notReserved = true
            break
          }
        }
        if (notReserved) {
          return 'bg-warning'
        }
        // const isItNotReserved = checkReserve.some(e=>)
        // if (this.isBundleReserved) {
        //   return 'bg-yellow-6'
        // }
        return ''
      }

    },
    warehouse () {
      if (this.options.warehouse) {
        return this.options.warehouse
      }

      if (this.options.reserve && this.options.reserve.warehouse) {
        const base = {
          id: this.options.reserve.warehouse,
          name: ''
        }

        if (!this.options.warehouses) {
          return base
        }

        return this.options.warehouses.find(({ id }) => `${id}` === `${base.id}`) || base
      }
      return {
        id: '',
        name: ''
      }
    }
  },
  mounted () {
    this.count = this.data.row.count
    this.price = this.data.row.price
    if (this.data.row && this.data.row._embedded && this.data.row._embedded?.productOffer && this.data.row._embedded?.productOffer?.eav) {
      this.marketplace = this.data.row._embedded?.productOffer?.eav['integrations-amazon-offer-marketplace']
    } else {
      this.marketplace = 'ATVPDKIKX0DER'
    }
    // this.marketplace = this.data.row?._embedded?.productOffer?.eav['integrations-amazon-offer-marketplace'] ? this.data.row._embedded?.productOffer?.eav['integrations-amazon-offer-marketplace'] : "ATVPDKIKX0DER"
    this.handleSKU(this.data.row)
  },
  beforeMount () {
    this.isLoading = true
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'setHasOrderChange',
      'upsertOffer'
    ]),
    handleSKU (product) {
      let sourceId = ''
      if (this.order?._embedded?.source) {
        sourceId = this.order._embedded?.source?.id
        this.sourceId = sourceId
      }
    },
    // updateOfferSku (key, value) {
    //   const type = this.order.type === 'wholesale' ? 'integrations-amazon-offer-sku-fba' : 'integrations-amazon-offer-sku-fbm'
    //   const objectKey = this.key ? this.key : this.sourceId
    //   const marketplace = this.marketplace ? this.marketplace : "ATVPDKIKX0DER"
    //   const update = {
    //       [key]: {
    //           [type]: {
    //               [marketplace] : {
    //                   [objectKey] : value
    //               }
    //           }
    //       }
    //   }
    //   this.updatedSKU = update
    // },
    // handleSkuSubmit () {
    //     this.$service.offer.save( this.updatedSKU, this.data.row.productOfferRaw.id, this.data.row._embedded.shop.id, {})
    // },
    toggleSupplies () {
      this.$refs.suppliesModal.open()
    },
    save (service) {
      service['orderProduct'] = this.data.row.id
      return this.$service.productsService.save({ ...service, order: this.order.id })
    },
    openCofirmation () {
      this.areYouSure = true
    },
    closeConfirmation () {
      this.areYouSure = false
    },
    handleReserveClick (reserve) {
      let result = this.reserves.filter(e => e.offer === reserve._embedded?.productOffer?.id)

      if (result) {
        this.$emit('open-reserve', result)
      }
    },
    onImageLoadFailure (event) {
      event.target.src = this.appOptions.fallbackImage
    },
    checkQuantity () {
      if (!this.quantityReserved) {
        this.options.reserve.forEach(e => this.quantityReserved += e.items)
      }
    },
    handleShowInfo (offer) {
      this.$emit('open', offer)
    },
    handleBundleNumberUpdate (value) {
      this.$emit('change', value)
    },
    handleNumberUpdate (key, value) {
      if (key === 'price' && typeof this.options.minPrice === 'number' && value < this.options.minPrice) {
        this.addErrorNotification(`Minimum price is ${this.options.minPrice}!`)
        return
      }

      if (key === 'count' && typeof this.options.minAmount === 'number' && value < this.options.minAmount) {
        this.addErrorNotification(`Minimum count is ${this.options.minAmount}!`)
        return
      }

      this.$emit('change', { product: this.data.row, update: { [key]: value } })
    },
    deleteBundleRow (value) {
      this.$emit('delete', value)
    },
    deleteRow () {
      this.$emit('delete', this.data.row._embedded?.productOffer?.id)
    },
    onClick: (item, ref) => {
      const options = {
        entityClass: 'Orderadmin\\Products\\Entity\\Product\\Offer'
      }

      if (item.row && item.row._embedded && item.row._embedded.productOffer && item.row._embedded.productOffer.eav) {
        ref.open(item.row._embedded.productOffer.eav, options)
      }

    },
    resetQuantityReserved () {
      return this.quantityReserved = 0
    },
    handleBundleClick () {
      if (this.bundleDropDown) {
        this.bundleDropDown = false
        return
      }

      this.itemsForTable = this.bundleProducts.filter(e => e.eav['products-order-bundle-order-product'] === this.data.row.id)

      this.bundleDropDown = true
    },
    getReserve (item) {

      const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }
      const grouped = groupBy(this.reserves, 'offer')
      const result = Object.values(grouped)

      return result.filter(reserve => reserve).find(reserve => `${reserve[0].offer}` === `${item._embedded.productOffer.id}`)
    },
    handleReserve (reserve) {
      this.$emit('open-reserve', reserve)
    },
    executeInstructions (data) {
      let instructions
      if (data.eav['storage-assembling-instructions']) {
        instructions = data.eav['storage-assembling-instructions']
      }
      const reducedInstructions = instructions.reduce((acc, instruction) => {
        if (Array.isArray(instruction)) {
          acc = [...acc, ...instruction]
        } else {
          acc.push(instruction)
        }

        return acc
      }, [])

      return this.instructionsService.execute(reducedInstructions)
          .then(result => {
            return result
          })
    },
    handleClick (key) {
      const result = key.label
      this.selectedOption = result
      this.$refs.mySelect.hidePopup()
      this.$emit('change', { product: this.data.row, update: { eav: { 'integrations-amazon-offer-sku': result } } })
      // if (this.order.type === 'wholesale') {
      //
      //   const test = key.label.split(':')
      //     this.$emit('change', { product: this.data.row, update: { eav:{'integrations-amazon-offer-sku': test[1]}} })
      //   // this.$service.offer.save({eav: {'integrations-amazon-offer-sku-fba': {[this.marketplace]: {[test[0]]: test[1]}}}}, this.data.row._embedded?.productOffer?.id, this.order._embedded.shop.id, {})
      //   //   .then(item => {
      //   //     this.upsertOffer(item)
      //   //     this.getMsku(this.marketplace)
      //   //     this.$router.go()
      //   //   })
      // } else {
      //
      //   const test = key.label.split(':')
      //   this.$emit('change', { product: this.data.row, update: { eav:{'integrations-amazon-offer-sku': test[1]}} })
      //   this.$service.offer.save({eav: {'integrations-amazon-offer-sku-fbm': {[this.marketplace]: {[test[0]]: test[1]}}}}, this.data.row._embedded?.productOffer?.id, this.order._embedded.shop.id, {})
      //     .then(item => {
      //       this.upsertOffer(item)
      //       this.getMsku(this.marketplace)
      //       this.$router.go()
      //     })
      // }
    },
    getMsku (marketplace) {
      let type = 'fbm'
      if (this.order.type === 'wholesale') {
        type = 'fba'
      }

      if (!this.order._embedded?.source?.id) {
        return
      }

      if (this.data.row._embedded?.productOffer?.eav) {
        let currentValue = this.data.row._embedded?.productOffer?.eav['integrations-amazon-offer-sku-' + type][marketplace][this.order._embedded.source.id]

        this.optionsArray.push({
          label: currentValue,
          value: currentValue
        })
      }

      if (this.data?.row?._embedded?.productOffer?.eav && this.data.row._embedded?.productOffer?.eav['integrations-amazon-offer-sku-' + type + '-list'] && marketplace) {
        let msku = this.data.row._embedded?.productOffer?.eav['integrations-amazon-offer-sku-' + type + '-list'][marketplace]
        if (Array.isArray(msku)) {
          msku.map(option => {
            let key = Object.keys(option)[0]

            this.optionsArray.push({
              label: option[key],
              value: option[key]
            })
          })
        }

        console.log(this.optionsArray)
      }

      if (this.data?.row?.eav && this.data.row.eav['integrations-amazon-offer-sku']) {
        const choosenMsku = this.data.row.eav['integrations-amazon-offer-sku']

        if (choosenMsku) {
          this.selectedOption = this.data.row.eav['integrations-amazon-offer-sku']
        }
      }
    },
    handleDuplicate () {
      this.$emit('duplicate', this.data.row)
    },
    handleClickWhere () {
      this.$refs.orderAllocation.open(this.order, this.data.row)
    },
    addNewOption () {
      this.newOption.key = this.sourceId
      if (this.order.type === 'wholesale') {
        let updatedList = []
        if (Array.isArray(this.msku) && this.msku) {
          updatedList = [...this.msku, { [this.newOption.key]: this.newOption.label }]
          this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fba-list': { [this.marketplace]: updatedList } } }, this.data.row._embedded?.productOffer?.id, this.order._embedded.shop.id, {})
              .then(item => {
                this.upsertOffer(item)
                // this.$refs.mySelect.hidePopup()
                const updatedOptions = [...this.optionsArray, this.newOption.label]
                this.optionsArray = updatedOptions
              })
              .then(() => {
                this.newOption.key = ''
                this.newOption.label = ''
                this.addOptionDialog = false
              })
        } else {
          this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fba-list': { [this.marketplace]: [{ [this.newOption.key]: this.newOption.label }] } } }, this.data.row._embedded?.productOffer?.id, this.order._embedded.shop.id, {})
              .then(item => {
                this.upsertOffer(item)
                // this.$refs.mySelect.hidePopup()
                const updatedOptions = [...this.optionsArray, this.newOption.label]
                this.optionsArray = updatedOptions
              })
              .then(() => {
                this.newOption.key = ''
                this.newOption.label = ''
                this.addOptionDialog = false
              })
        }
      } else {
        let updatedList = []
        if (Array.isArray(this.msku) && this.msku) {
          updatedList = [...this.msku, { [this.newOption.key]: this.newOption.label }]
          this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fbm-list': { [this.marketplace]: updatedList } } }, this.data.row._embedded?.productOffer?.id, this.order._embedded.shop.id, {})
              .then(item => {
                this.upsertOffer(item)
                // this.$refs.mySelect.hidePopup()
                const updatedOptions = [...this.optionsArray, this.newOption.label]
                this.optionsArray = updatedOptions
              })
              .then(() => {
                this.newOption.key = ''
                this.newOption.label = ''
                this.addOptionDialog = false
              })
        } else {
          this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fbm-list': { [this.marketplace]: [{ [this.newOption.key]: this.newOption.label }] } } }, this.data.row._embedded?.productOffer?.id, this.order._embedded.shop.id, {})
              .then(item => {
                this.upsertOffer(item)
                // this.$refs.mySelect.hidePopup()
                const updatedOptions = [...this.optionsArray, this.newOption.label]
                this.optionsArray = updatedOptions
              })
              .then(() => {
                this.newOption.key = ''
                this.newOption.label = ''
                this.addOptionDialog = false
              })
        }

      }

    },
  }
}
</script>
