<template>
  <q-card>
    <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
      <q-legend :label="$t('Assembling Tasks')" />


<!--      <search-->
<!--          dense-->
<!--          autoset-->
<!--          is-expandable-->
<!--          @submit="handleSearch"-->
<!--      />-->

<!--      <q-space />-->

<!--      <q-btn-->
<!--          v-if="!hideActions"-->
<!--          :color="filters.length > 0 ? 'light-blue-9' : 'dark'"-->
<!--          text-color="white"-->
<!--          size="sm"-->
<!--          class="q-mr-sm"-->
<!--          :label="filterBtnText"-->
<!--          no-caps-->
<!--          unelevated-->
<!--          @click="toggleFilter"-->
<!--      />-->

<!--      <q-btn-->
<!--          v-if="!hideActions"-->
<!--          color="dark"-->
<!--          text-color="white"-->
<!--          :label="$t('Refresh')"-->
<!--          size="sm"-->
<!--          class="q-mr-sm"-->
<!--          no-caps-->
<!--          unelevated-->
<!--          @click="refreshItems"-->
<!--      />-->
    </q-card-section>



      <q-card-section>
        <q-table
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="tasks"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="isTasksLoading"
            :filter="tableFilter"
            @request="onRequest"
            virtual-scroll
            binary-state-sort
            flat
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
            >
              <q-td
                  key="id"
                  :props="props"
                  class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                  key="state"
                  :props="props"
                  class="text-subtitle1"
              >
                <q-badge
                    :color="stateColor[props.row.state]"
                    :label="$t(props.row.state)"
                />
              </q-td>

              <q-td
                  key="executive"
                  :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.executive">
                  {{ props.row._embedded.executive.name }}
                </span>

                <span v-else>--</span>
              </q-td>

              <q-td
                  key="updated"
                  :props="props"
              >
                {{ $moment(props.row.updated.date).format('HH:mm') }}
              </q-td>
              
              <q-td
                key="queue"
                :props="props"
                >
                <span v-if="props.row._embedded && props.row._embedded.queue">
                  {{ props.row._embedded.queue.id }}
                </span>

                <span v-else>--</span>
              </q-td>

              <q-td
                  key="place"
                  :props="props"
              >
                <span v-if="props.row._embedded?.place">
                  <new-picking-place
                      :data="props.row._embedded.place"
                      :type="props.row._embedded.place.type"
                      badge
                  />
                </span>

                <span v-else>--</span>
              </q-td>

              <q-td
                  key="order"
                  :props="props"
              >
                {{ getOrderID(props.row) || '--' }}
              </q-td>

              <q-td
                  key="quantity"
                  :props="props"
              >
                {{ `${props.row.scannedCount || 0}/${props.row.count || 0}` }}
              </q-td>

              <q-td
                  key="product"
                  :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.productOffer">
                  {{ `${props.row._embedded.productOffer.name} (${props.row._embedded.productOffer.id})` }}
                </span>

                <span v-else>--</span>
              </q-td>

              <q-td
                  key="shop"
                  :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.shop">
                  {{ props.row._embedded.shop.name }}
                </span>

                <span v-else>--</span>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
</template>

<script>
// Utils
import { buildQuery } from '../../utils/query-utils'
import {mapGetters} from "vuex";
import NewPickingPlace from '@/apps/app/components/picking/new-picking-place.vue'

export default {
  name: 'AssemblingTasksModal',
  components: { NewPickingPlace },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      title: 'Packing Tasks',
      filter: [],
      tableFilter: '',
      pagination: {
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5
      },
      stateColor: {
        new: 'lime-5 text-white',
        confirmed: 'lime-4 text-white',
        complete: 'lime-3 text-white',
        rejected: 'red-4 text-dark'
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Executive'),
          name: 'executive',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Time'),
          name: 'updated',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Queue'),
          name: 'queue',
          align: 'left'
        },
        {
          label: this.$t('Location'),
          name: 'place',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'quantity',
          align: 'left'
        },
        {
          label: this.$t('Product'),
          name: 'product',
          align: 'left'
        },
        {
          label: this.$t('Shop'),
          name: 'shop',
          align: 'left',
          sortable: true
        }
      ],
      tasks: [],
      isTasksLoading: false
    }
  },
  mounted() {
    this.onRequest()
  },
  computed: {
    ...mapGetters([
      'order',
    ]),
  },
  methods: {
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    getOrderID (task) {
      if (task._embedded.order) {
        return task._embedded.order.id
      }

      if (task._embedded.reserve && task._embedded.reserve._embedded && task._embedded.reserve._embedded.order) {
        return task._embedded.reserve._embedded.order._links.self.href.split('/').pop()
      }

      return false
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (!query.filter) {
        query.filter = []
      }

      query.filter = [
        ...query.filter,
        ...this.filter
      ]

      if (!query['order-by']) {
        query['order-by'] = [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }
      query.filter = [{type: 'eq', field: 'order', value: this.order.id}]
      this.isTasksLoading = true

      return this.$service.assemblingTask.getAll(query)
          .then(({ items, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              rowsNumber: totalItems
            }

            this.tasks = items
          })
          .finally(() => {
            this.isTasksLoading = false
          })
    }
  }
}
</script>
