<template>
  <div>
    <div
      v-if="!noFields"
      class="row items-center justify-end"
    >
      <div class="col-4 q-pa-sm">
        <form-builder v-if="interaction" :schema="schema" />
      </div>

      <div class="col-2">
        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Add interaction')"
          :disable="!interaction || !interaction._embedded.task"
          no-caps
          unelevated
          @click="save"
        />
      </div>
    </div>

    <q-table
      row-key="id"
      :rows-per-page-label="$t('Rows per page')"
      :rows="interactions"
      :columns="columns"
      v-model:pagination="pagination"
      :loading="interactionsLoading"
      :filter="filter"
      virtual-scroll
      binary-state-sort
      @request="onRequest"
    >
      <template v-slot:loading>
        <q-inner-loading
          showing
          color="primary"
        />
      </template>

      <template v-slot:body="props">
        <q-tr
          :props="props"
          class="clickable"
          @dblclick="openInteraction(props.row)"
        >
          <q-td
            key="id"
            :props="props"
            class="text-subtitle1"
          >
            <strong>{{ props.row.id }}</strong>

            <br>

            {{ props.row.reason }}
          </q-td>

          <q-td
            key="state"
            :props="props"
          >
            <q-badge
              :color="stateColors[props.row.state]"
              text-color="dark"
              class="q-pa-sm"
            >
              {{ $t(props.row.state) }}
            </q-badge>
          </q-td>

          <q-td
            key="operator"
            :props="props"
          >
            <span v-if="hasValue(props.row.eav['integrations-asterisk-call'], 'oper')">
              {{ props.row.eav['integrations-asterisk-call'].oper }}
            </span>

            <span v-else> - </span>
          </q-td>

          <q-td
            key="disposition"
            :props="props"
          >
            <span v-if="hasValue(props.row.eav['integrations-asterisk-call'], 'disposition')">
              <span :class="'rounded q-px-sm q-py-xs text-white bg-' + callColors[props.row.eav['integrations-asterisk-call'].disposition]">
                {{ $t(props.row.eav['integrations-asterisk-call'].disposition) }}
              </span>
            </span>

            <span v-else> - </span>
          </q-td>

          <q-td
            key="order"
            :props="props"
          >
            <span v-if="props.row._embedded && props.row._embedded.order">
              {{ props.row._embedded.order.id }}
            </span>
          </q-td>

          <q-td
            key="deliveryRequest"
            :props="props"
          >
            <span v-if="props.row._embedded && props.row._embedded.deliveryRequest">
              {{ props.row._embedded.deliveryRequest.id }}
            </span>
          </q-td>

          <q-td
            key="reason"
            :props="props"
          >
            <span v-if="props.row.reason">
              {{ props.row.reason }}
            </span>

            <span v-else> - </span>
          </q-td>

          <q-td
            key="created"
            :props="props"
          >
            {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
          </q-td>

          <q-td
            key="updated"
            :props="props"
          >
            {{ $moment(props.row.updated.date).format(appOptions.formats.date) }}
          </q-td>

          <q-td
            key="duration"
            :props="props"
          >
            <span v-if="hasValue(props.row.eav['integrations-asterisk-call'], 'cdrdur')">
              {{ props.row.eav['integrations-asterisk-call'].cdrdur }}
            </span>

            <span v-else> - </span>
          </q-td>

          <q-td
            key="file"
            :props="props"
          >
            <a
              v-if="hasAudio(props.row.eav.files)"
              :href="props.row.eav.files.audio"
              target="_blank"
            >
              {{ $t('Download') }}
            </a>

            <span v-else> - </span>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Services
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'Calls',
  props: {
    noFields: {
      type: Boolean,
      default () {
        return false
      }
    },
    shop: {
      type: Object,
      default () {
        return null
      }
    },
    order: {
      type: Object,
      default () {
        return null
      }
    },
    deliveryRequest: {
      type: Object,
      default () {
        return null
      }
    },
    searchBy: {
      type: String,
      default () {
        return 'order'
      }
    }
  },
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      stateColors: {
        pending_queued: 'default',
        pending: 'yellow',
        new: 'green',
        pending_error: 'danger',
        cancel: 'dark',
        confirmed: 'yellow',
        partly_reserved: 'yellow',
        reserved: 'info',
        assembling: 'info',
        assembled: 'info',
        sent: 'info',
        delivery: 'info',
        complete: 'success',
        complete_partly: 'success',
        denied: 'dark',
        lost: 'pink',
        disposed: 'dark',
        error: 'danger',
        returning: 'purple',
        returning_partly: 'purple',
        return_waiting: 'purple',
        returned: 'purple',
        returned_partly: 'purple',
        complete_returned: 'success'
      },
      callColors: {
        ANSWERED: 'success',
        REFUSED: 'danger'
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Operator'),
          name: 'operator',
          align: 'left'
        },
        {
          label: this.$t('Disposition'),
          name: 'disposition',
          align: 'left'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left'
        },
        {
          label: this.$t('Shipping Request'),
          name: 'deliveryRequest',
          align: 'left'
        },
        {
          label: this.$t('Reason'),
          name: 'reason',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Updated'),
          name: 'updated',
          align: 'left'
        },
        {
          label: this.$t('Duration'),
          name: 'duration',
          align: 'left'
        },
        {
          label: this.$t('Download file'),
          name: 'file',
          align: 'left'
        }
      ],
      currentShop: null
    }
  },
  computed: {
    ...mapGetters([
      'interaction',
      'interactions',
      'interactionsLoading',
      'appOptions'
    ]),
    schema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Choose task'),
                field: 'task',
                value: this.interaction._embedded.task,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.notificationTask.getAll(query)
                },
                onChange: (defaultNotificationTask) => {
                  this.updateInteraction({ _embedded: { ...this.interaction._embedded, task: defaultNotificationTask } })
                  this.updateAppOptions({ defaultNotificationTask })
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.loadDefaultItems()
    this.setNewInteraction()

    if (this.appOptions.defaultNotificationTask) {
      this.updateInteraction({ _embedded: { task: this.appOptions.defaultNotificationTask } })
    }

    this.setEntityClass()

    this.loadShop()
      .then(shop => {
        this.currentShop = shop
      })
  },
  methods: {
    ...mapActions([
      'loadInteractions',
      'saveInteraction',
      'loadNotificationTasks'
    ]),
    ...mapMutations([
      'updateAppOptions',
      'setNewInteraction',
      'setInteractions',
      'updateInteraction',
      'addErrorNotification'
    ]),
    loadDefaultItems () {
      const pagination = {
        per_page: 25,
        page: 1,
        filter: [
          this.getSearchFilter()
        ],
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      return this.onRequest({ pagination })
    },
    getSearchFilter () {
      const filters = {
        order: () => {
          return { field: 'order', type: 'eq', value: this.order.id }
        },
        deliveryRequest: () => {
          return { field: 'deliveryRequest', type: 'eq', value: this.deliveryRequest.id }
        },
        shop: () => {
          return { field: 'shop', type: 'eq', value: this.shop.id }
        }
      }

      return filters[this.searchBy]()
    },
    setEntityClass () {
      const setters = {
        order: () => {
          if (!this.order || !this.order.id) {
            return
          }

          this.updateInteraction({
            enityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
            entity: this.order.id
          })
        },
        deliveryRequest: () => {
          if (!this.deliveryRequest || !this.deliveryRequest.id) {
            return
          }

          this.updateInteraction({
            enityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
            entity: this.deliveryRequest.id
          })
        }
      }

      if (setters[this.searchBy]) {
        setters[this.searchBy]()
      }

      return this.interaction
    },
    loadShop () {
      if (!this.shop) {
        return Promise.resolve(null)
      }

      if (this.shop._embedded) {
        return Promise.resolve(this.shop)
      }

      return this.$service.shop.get(this.shop.id)
    },
    save () {
      return this.saveInteraction()
    },
    hasAudio (row) {
      return row && row.audio
    },
    hasValue (value, key) {
      return value && value[key]
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      return this.loadInteractions(query)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    createQuery (loader, params) {
      const query = {
        per_page: 25,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ],
        ...params
      }

      if (loader === 'notificationTasks') {
        if (this.currentShop && this.currentShop._embedded) {
          query.filter.push({
            type: 'eq',
            field: 'owner',
            value: this.currentShop._embedded.owner.id
          })
        }

        // query.filter.push({
        //   type: 'eq',
        //   field: 'type',
        //   value: 'manual'
        // })
      }

      return query
    },
    loadNextItems (search, page, loader) {
      const loaders = {
        notificationTasks: this.loadNotificationTasks
      }

      if (typeof loaders[loader] !== 'function') {
        this.addErrorNotification('Items loader is unknown!')
        return
      }

      const query = this.createQuery(loader, { search, page })

      return loaders[loader](query)
    },
    handleChange (type) {
      const types = {
        notificationTasks: {
          getter: 'notificationTasks',
          page: 'notificationTasksPage',
          loadedValue: 'loadedNotificationTasks'
        }
      }

      const current = types[type]

      if (!current) {
        this.addErrorNotification('Unable to handle filter change!')
        return
      }

      if (this[current.page] <= 1) {
        this[current.loadedValue] = this[current.getter]
      } else {
        this[current.loadedValue] = [
          ...this[current.loadedValue],
          ...this[current.getter]
        ]
      }
    }
  }
}
</script>
