<template>
  <q-dialog v-model="isOpen" class="modal-xl">
    <q-card>
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Where ?') }}
        </div>

        <q-space />

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <q-card-section>
        <delivery-requests-table :hide-actions="isClient" />
      </q-card-section>

      <q-card-section>
        <order-tasks-table :hide-actions="isClient" />
      </q-card-section>

      <q-card-section>
        <order-picking-tasks-table :hide-actions="isClient"/>
      </q-card-section>

      <q-card-section>
        <order-items-table :hide-actions="isClient"/>
      </q-card-section>

      <q-card-section>
        <order-reserve-table :hide-actions="isClient"/>
      </q-card-section>

    </q-card>
  </q-dialog>
</template>

<script>
// Components
import OrderPickingTasksTable from '../tables/OrderPickingTasksTable.vue'
import DeliveryRequestsTable from '../tables/DeliveryRequestsTable.vue'
import OrderTasksTable from '../tables/OrderTasksTable.vue'
import OrderItemsTable from '../tables/OrderItemsTable.vue'
import OrderReserveTable from '../tables/OrderReserveTable.vue'

// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'WhereModal',
  components: {
    DeliveryRequestsTable,
    OrderTasksTable,
    OrderPickingTasksTable,
    OrderItemsTable,
    OrderReserveTable
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'isClient'
    ])
  },
  methods: {
    open () {
      this.isOpen = true
    },
    hide () {
      this.isOpenFilter = false
      this.isOpen = false
    }
  }
}
</script>
