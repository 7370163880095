<template xmlns="http://www.w3.org/1999/html">
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
    <q-td
        key="id"
        :props="data"
        auto-width
    >
      <strong>{{data.row.id}}</strong>
    </q-td>

    <q-td
      key="state"
      :props="data"
      auto-width
      >
      <super-dropdown :model="schema" />
    </q-td>

    <q-td
      key="type"
      :props="data"
      auto-width
      >
      {{data.row.type}}
    </q-td>

    <q-td
      key="productOffer"
      :props="data"
      auto-width
      >
      {{data.row._embedded?.productOffer?.name}}
    </q-td>

    <q-td
        key="warehouse"
        :props="data"
        auto-width
    >
      {{data.row._embedded.warehouse.name}}
    </q-td>

    <q-td
      key="reserve"
      :props="data"
      auto-width
      >
      {{data.row._embedded.reserve ? data.row._embedded.reserve.id : '-'}}
    </q-td>

    <q-td
      key="place"
      :props="data"
      auto-width
      >
      <span v-if="data.row._embedded?.place">
        <new-picking-place
            :data="data.row._embedded.place"
            :type="data.row._embedded.place.type"
            badge
        />
      </span>
    </q-td>

    <q-td
        key="sku"
        :props="data"
        auto-width
    >
      {{data.row.sku}}
    </q-td>

    <q-td
        key="price"
        :props="data"
        auto-width
    >
      {{data.row.price}}
    </q-td>

    <q-td
        key="dimensions"
        :props="data"
        auto-width
    >
      {{'Weight: ' + (data.row.weight?data.row.weight: '0') + appOptions.defaultDimensions.weightUnit}}
      <br>
      {{'Width: ' + (data.row.dimensions ?data.row.dimensions.x : '0') + appOptions.defaultDimensions.dimensionUnit}}
      <br>
      {{'Height: ' + (data.row.dimensions ?data.row.dimensions.y: '0') + appOptions.defaultDimensions.dimensionUnit}}
      <br>
      {{'Length: ' + (data.row.dimensions ?data.row.dimensions.z: '0') + appOptions.defaultDimensions.dimensionUnit}}
      <br>
      {{'Volume: ' + data.row.volume}}
    </q-td>

    <q-td
        key="document"
        :props="data"
        auto-width
    >
      {{'Created By: ' + (data.row['created_by_document_id'] ? data.row['created_by_document_id'] : '-')}}
      <br>
      {{'Shipped By: ' + (data.row['shipped_by_document_id'] ? data.row['shipped_by_document_id'] : '-')}}
    </q-td>

    <q-td
        key="batch"
        :props="data"
        auto-width
    >
      {{data.row.batch ? data.row.batch: '-'}}
    </q-td>

  </q-tr>
</template>

<script>
import { mapMutations , mapGetters } from 'vuex'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown.vue'
import NewPickingPlace from '@/apps/app/components/picking/new-picking-place.vue'

export default {
  name: 'ItemsRow',
  emits: ['check', 'dblclick'],
  components: {
    NewPickingPlace,
    SuperDropdown
  },
  props: {
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      itemStates: [
        {
          buttons: [
            { id: 'normal', title: 'Normal', transitions: [], color: 'success' },
            { id: 'delivered', title: 'Delivered', transitions: [], color: 'positive' },
            { id: 'blocked', title: 'Blocked', transitions: [], color: 'danger' },
            { id: 'booked', title: 'Booked', transitions: [], color: 'danger' },
            { id: 'deleted', title: 'Deleted', transitions: [], color: 'danger' },
            { id: 'new', title: 'New', transitions: [], color: 'grey-3'},
            { id: 'returned', title: 'Returned', transitions: [], color: 'warning'},
            { id: 'shipped' , title: 'Shipped', transitions: [], color: 'green text-white'},
            { id: 'expected', title: 'Expected' , transitions: [], color: 'green text-white'},
            { id: 'defected', title: 'Defected', transitions: [], color : 'dark text-white'}
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
    ]),
    schema () {
      const value = this.itemStates[0].buttons.find(({ id }) => id === this.data.row.state) || { id: 'normal', title: 'Active' }
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {

        },
        value,
        options: this.itemStates
      }

      return option
    }
  },
  methods: {
    ...mapMutations([
    ]),
    onRowDblClick () {
      this.$emit('dblclick', this.data.row)
    },
    handleCheck () {
      this.$emit('check', this.data.row)
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    }
  }
}
</script>