<template>
    <q-dialog v-model="isOpen" class="modal-xl">
        <q-card>
            <q-card-section class="row">
                <div class="text-h6 text-center">
                    {{ $t('Supplies list') }}
                </div>

                <q-space />

                <q-btn
                        color="transparent"
                        text-color="dark"
                        size="sm"
                        icon="close"
                        no-caps
                        unelevated
                        @click="close"
                />
            </q-card-section>

            <q-card-section class="q-pa-none">
                <supplies-table
                        flat
                        :ext-filter="extFilter"
                        @on-dblclick="handleClick"
                />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
// Components
import SuppliesTable from '../tables/SuppliesTable.vue'

export default {
    name: 'OrderProductSuppliesModal',
    components: {
        SuppliesTable
    },
    data () {
        return {
            isOpen: false,
            extFilter: []
        }
    },
    methods: {
        handleClick (supply) {
            this.$emit('on-submit', { name: supply.name, price: supply.price, supply: supply.id , count: supply.quantity})
            this.close()
        },
        close () {
            this.isOpen = false
        },
        open (extFilter) {
            this.isOpen = true
            this.extFilter = extFilter
        }
    }
}
</script>
