<template>
  <q-tr
      :props="data"
      :class="color"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
      {{ data.rowIndex + 1 }}
    </q-td>

    <q-td
        key="image"
        :props="data"
        style="width: 35%"
    >
      <q-card style="min-width: 100px;max-width: 50%; text-align: center">
        <div>
          <img
              :src="data.row._embedded.productOffer.image || appOptions.fallbackImage"
              style="height: 50%; width: 50%; object-fit: contain;"
              @error="onImageLoadFailure"
          >
          <q-chip class="absolute-top-right text-weight-bold cursor-pointer" :color="chipColor" clickable @click="handleShowInfo(data.row._embedded.productOffer)" >
            {{ data.row._embedded.productOffer.type }}
          </q-chip>
        </div>
      </q-card>


    </q-td>

    <q-td
        key="name"
        :props="data"
        style="max-width: 150px; word-wrap: break-word; white-space: normal !important; width: 25%"
    >
      <div class="text-subtitle2 q-my-sm">
        {{ name }}
      </div>

      <span
          v-if="!data.row._embedded.productOffer.items || data.row._embedded.productOffer.items.length === 0 || !data.row._embedded.productOffer.items[0]">
        <strong>-</strong>
      </span>

      <div v-else>
        <items-table
            :items="data.row._embedded.productOffer.items"
            :offer="data.row._embedded.productOffer"
            style="max-width: 250px"
            show-details
        />
      </div>

    </q-td>

    <q-td
        key="reserves"
        :props="data"
        style="width: 20%"
    >
      <div  class="clickable" @dblclick="handleReserveClick(data.row)">
      <strong style="font-size: 200%" v-if="data.row._embedded.productOffer.type !== 'bundle'">
        {{ `${quantityReserved}/${data.row.count}` }}
      </strong>

      <strong v-else style="font-size: 200%">
        <span> {{ data.row.count }}</span>
      </strong>
      </div>

    </q-td>

    <q-td
        key="total"
        :props="data"
        style="width: 20%"
    >
      <div>
        <strong>{{ 'Price: ' + data.row.price }}</strong>

      </div>

      <br>

      <div>
        <strong>{{ 'Quantity: ' + data.row.count }}</strong>
      </div>

      <br>

      <div>
        <span> <strong>{{ 'Total: ' + total }}</strong></span>
      </div>

    </q-td>

    <q-td
        key="after"
        ref="eavModal"
    >
      <q-list>
        <q-item clickable v-close-popup>
          <q-item-section @click="onClick(data , this.$refs.eavModal)">
            <q-item-label>
              {{ 'EAV' }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup :disable="disabledDelete">
          <q-item-section @click="openConfirmation">
            <q-item-label>
              {{ 'Delete' }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-td>
    <eav-modal ref="eavModal">
      <form-builder :schema="schema2"/>
    </eav-modal>
  </q-tr>

  <q-dialog v-model="areYouSure">
    <q-card style="max-width: 30vw">
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
          {{ $t('Are You Sure?') }}
        </div>

        <q-space/>

        <q-btn v-close-popup icon="close" flat round dense/>
      </q-card-section>
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <q-card-section class="text-center">

          <q-btn
              textarea="center"
              color="negative"
              class="q-mr-sm"
              text-color="white"
              :label="$t('Delete product')"
              @click="deleteRow"
          />

          <q-btn
              textarea="center"
              :label="$t('Cancel')"
              @click="closeConfirmation"
          />
        </q-card-section>
      </q-card-section>
    </q-card>
  </q-dialog>

</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import ItemsTable from '../items-table/ItemsTable.vue'
import EavModal from '../../components/modals/EavModal.vue'
import { InstructionsService } from '@/apps/app/services/instructions.service'

export default {
  name: 'OrderProductBundleRow',
  emits: ['open', 'change', 'delete', 'open-reserve'],
  components: {
    ItemsTable,
    EavModal
  },
  props: {
    options: {
      type: Object,
      default () {
        return {
          warehouses: [],
          reserve: null,
          warehouse: null,
          minPrice: 0,
          minAmount: 0
        }
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabledDelete: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Object,
      required: true
    },
    bundleProducts: {
      type: Object
    },
    order: {
      type: Object,
      default () {
        return null
      }
    },
    reserves: {
      type: Array,
      default () {
        return []
      }
    },
  },
  data () {
    return {
      stateColor: {
        new: 'grey-4',
        picking: 'teal-12',
        cancelled: 'danger',
        confirmed: 'teal'
      },
      quantityReserved: 0,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      isLoading: false,
      items: [],
      itemsForTable: [],
      areYouSure: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    name () {
      const name = this.data.row._embedded.productOffer.name

      if (this.data.row.id) {
        return `${name} (${this.data.row.id})`
      }

      return name
    },
    schema2 () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.data.row.extId,
                label: this.$t('Ext ID'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
              }
            ]
          }
        ]
      }
    },
    columns () {
      return [
        {
          name: 'number',
          align: 'left'
        },
        {
          name: 'image',
          align: 'left'
        },
        {
          name: 'name',
          align: 'left'
        },
        {
          name: 'reserves',
          align: 'center',
          headerClasses: this.order && this.order.type === 'return'
              ? 'd-none'
              : '',
          classes: this.order && this.order.type === 'return'
              ? 'd-none'
              : '',
        },
        {
          name: 'total',
          align: 'left'
        },
        {
          name: 'after',
          align: 'right'
        }
      ]
    },
    total () {
      return ((this.data.row.price || 0) * (this.data.row.count || 0)).toFixed(2)
    },
    reserve () {

      if (!this.options.reserve) {
        this.resetQuantityReserved()
        this.$emit('handle-color', true)
        return [{
          items: 0,
          id: this.$t('No reserve')
        }]
      }

      this.checkQuantity()

      if (this.data.row.count > this.quantityReserved) {
        this.$emit('handle-color', true)
      }

      return this.options.reserve
    },
    chipColor () {
      if (this.data.row._embedded.productOffer.type === 'bundle'){
        return 'accent'
      }
      return 'info'
    },
    color () {
      if (this.options.noColor) {
        return ''
      }

      if (this.reserve.items >= this.data.row.count || this.quantityReserved >= this.data.row.count) {
        return ''
      }

      return 'bg-yellow-6'
    },
    warehouse () {
      if (this.options.warehouse) {
        return this.options.warehouse
      }

      if (this.options.reserve && this.options.reserve.warehouse) {
        const base = {
          id: this.options.reserve.warehouse,
          name: ''
        }

        if (!this.options.warehouses) {
          return base
        }

        return this.options.warehouses.find(({ id }) => `${id}` === `${base.id}`) || base
      }
      return {
        id: '',
        name: ''
      }
    }
  },
  beforeMount () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))

    this.isLoading = true

  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    openConfirmation() {
      this.areYouSure = true
    },
    closeConfirmation() {
      this.areYouSure = false
    },
    handleReserveClick (reserve) {
      let result = this.reserves.filter(e=>e.offer === reserve._embedded?.productOffer?.id)

      if(result) {
        this.$emit('open-reserve', result)
      }
    },
    onImageLoadFailure (event) {
      event.target.src = this.appOptions.fallbackImage
    },
    checkQuantity () {
      if (!this.quantityReserved) {
        this.options.reserve.forEach(e => this.quantityReserved += e.items)
      }
    },
    handleShowInfo (offer) {
      this.$emit('open', offer)
    },
    deleteRow () {
      this.$emit('delete', this.data.row._embedded.productOffer.id)
    },
    onClick: (item, ref) => {
      const options = {
        entityClass: 'Orderadmin\\Products\\Entity\\Product\\Offer'
      }

      ref.open(item.row.eav, options)
    },
    resetQuantityReserved () {
      return this.quantityReserved = 0
    },
  }
}
</script>
