<template>
  <q-card>
    <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
      <q-legend :label="$t('Reserves')"/>

      <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
      />

      <q-space/>

      <q-btn
          v-if="!hideActions"
          :color="filters.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="toggleFilter"
      />

      <q-btn
          v-if="!hideActions"
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
      />

    </q-card-section>

    <filter-collapse
        :is-open="isOpen"
        :options="{
        defaultFilter: filters,
        fields: activatedFields,
        values: {
          states: statuses
        },
        style: {
          noGroups: true
        }
      }"
        @submit="handleFiltersSubmit"
        @close="toggleFilter"
    />

    <q-card-section class="q-ma-none q-pa-none">
      <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="reserves"
          :columns="columns"
          v-model:pagination="pagination"
          :loading='reservesLoading'
          :filter="filter"
          :table-header-class="$q.dark.isActive ? '' : (options.style && options.style.header) || ''"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
              showing
              color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
              :props="props"
              class="clickable"
          >
            <q-td
                key="reserve"
                :props="props"
            >
              {{ props.row.id }}
            </q-td>

            <q-td
                key="state"
                :props="props"
            >

              <super-dropdown :model="schema(props.row)"/>

            </q-td>

            <q-td
                key="priority"
                :props="props"
            >
              <div>
                {{ `${props.row.priority}` }}
              </div>

            </q-td>

            <q-td
                key="warehouse"
                :props="props"
            >
              <div v-if="props.row._embedded.warehouse">
                {{ `${props.row._embedded.warehouse.name} (${props.row._embedded.warehouse.id})` }}
              </div>

              <div v-else>
                {{ $t('No') }}
              </div>
            </q-td>

            <q-td
                key="count"
                :props="props"
            >
              {{ props.row.items }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'
import DeliveryRequestRowTable from '../../components/delivery-request-row-table/DeliveryRequestRowTable'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Utils
import { buildQuery } from '../../utils/query-utils'
import SuperDropdown from '@/apps/app/components/super-dropdown/SuperDropdown.vue'
import services from '@/apps/app/services/services'

export default {
  name: 'OrderReserveTable',
  components: {
    SuperDropdown,
    Search,
    FilterCollapse
  },
  mixins: [
    TableMixin
  ],
  props: {
    options: {
      type: Object,
      default () {
        return {
          filters: []
        }
      }
    },
    hideActions: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5
      },
      reserveStates: [
        {
          id: 1,
          group: 'Default',
          color: 'grey-1',
          buttons: [
            {
              id: 'new',
              title: 'Pending Queued',
              color: 'grey-4',
              editableField: false,
              transitions: []
            },
            {
              id: 'cancelled',
              title: 'Cancelled',
              color: 'grey-5',
              editableField: false,
              transitions: ['new']
            }
          ]
        },
        {
          id: 2,
          group: 'Confirmed',
          color: 'blue',
          buttons: [
            {
              id: 'confirmed',
              title: 'Confirmed',
              color: 'yellow-4',
              editableField: true,
              transitions: ['cancelled']
            },
          ]
        },
        {
          id: 3,
          group: 'Danger',
          color: 'danger',
          buttons: [
            {
              id: 'error',
              title: 'Error',
              editableField: true,
              transitions: ['cancelled', 'new']
            },
            {
              id: 'delete',
              title: 'Delete',
              editableField: false,
              transitions: []
            }
          ]
        },
        {
          id: 4,
          group: 'Prepearing',
          color: 'success',
          buttons: [
            {
              id: 'queued',
              title: 'Queued',
              color: 'cyan-14',
              editableField: true,
              transitions: ['cancelled']
            },
            {
              id: 'processing',
              title: 'Processing',
              color: 'cyan-14',
              editableField: true,
            },
            {
              id: 'picking',
              title: 'Picking',
              color: 'cyan-14',
              editableField: true,
            },
            {
              id: 'sorting',
              title: 'Sorting',
              color: 'cyan-14',
              editableField: true,
            },
            {
              id: 'distribution',
              title: 'Distribution',
              color: 'cyan-14',
              editableField: true,
            },
            {
              id: 'packaging',
              title: 'Packaging',
              color: 'cyan-14',
              editableField: true,
            },
          ]
        },
        {
          id: 5,
          group: 'Complete',
          color: 'info',
          buttons: [
            {
              id: 'complete',
              title: 'Complete',
              color: 'light-blue-2',
              editableField: false,
              transitions: []
            },
          ]
        },
      ],
      columns: [
        {
          label: this.$t('Reserve'),
          name: 'reserve',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Priority'),
          name: 'priority',
          align: 'left'
        },
        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'left'
        },
        {
          label: this.$t('Count'),
          name: 'count',
          align: 'left'
        }
      ],
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [],
      filters: [
        { type: 'eq', field: 'state', value: 'active' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isClient',
      'appOptions',
      'order',
      'reserves',
      'totalReservesNumber',
      'reservesLoading',
      'reservesPage',
    ]),
    filterBtnText () {
      return this.$t('Filter')
    },
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadReserves',
      'saveReserve',
    ]),
    ...mapMutations([
      'setReserves',
    ]),
    schema (row) {
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {
          this.$service.reserve.save({ state }, row.id)
              .then(item => {
                console.log(item)
              })
        },
        value: { id: 'new', title: 'New' },
        options: this.reserveStates
      }

      if (row.state !== option.value.id) {
        this.reserveStates.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === row.state
          })

          if (status) {
            option.color = group.color
            option.value = status
          }

          return status
        })
      }

      return option
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      this.filters = filter
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilter () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: { page: 1, rowsPerPage: 5 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query['order-by'] = [{ type: 'field', field: 'created', direction: 'desc' }]
      query.filter = [{ type: 'eq', field: 'order', value: this.order.id }]

      return this.loadReserves(query)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
    },
    createNewDeliveryRequest () {
      this.$router.push('/outbound/shipments/entity/')
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRowClick (item) {
      this.setDeliveryRequest(item)
      this.$router.push('/outbound/shipments/entity/' + item.id)
    },
  }
}
</script>
