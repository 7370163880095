<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 90vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Import Products by Sku') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-input
            standout="bg-teal text-white"
            type="textarea"
            :model-value="rawModel"
            :label="$t('Products')"
            @update:model-value="updateModel"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Load')"
            @click="load"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Save')"
            @click="save"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Close')"
            @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-card-section class="q-pt-lg q-mt-sm" v-if="isLoading">
          <q-linear-progress stripe size="10px" class="" ref="progress" :value="progressValue"/>
        </q-card-section>
      </q-card-section>

      <q-card-section v-if="model.length > 0">
        <q-list bordered separator :class="{ 'bg-warning': item?.state === 'error' }">
          <q-item v-for="item in model" :key="item.sku" v-ripple class="row">
            <q-item-section avatar>
              <img
                  style="max-height: 50px; width: 60px; object-fit: contain"
                  :src="item.offer?.image || fallbackImage"
                  @error="onImageLoadFailure"
              />
            </q-item-section>

            <q-item-section class="col-2">
              <q-input
                  standout="bg-teal text-white"
                  type="text"
                  :model-value="item.sku"
                  :label="$t('SKU')"
                  :readonly="true"
              />
            </q-item-section>

            <q-item-section class="col-2">
              <q-input
                  standout="bg-teal text-white"
                  type="text"
                  :model-value="item.rootValue"
                  :label="$t('Root value')"
                  @update:model-value="updateItem(item.sku,'rootValue', $event)"
              />
            </q-item-section>

            <q-item-section>
              <span v-if="item.offer?.id">
                {{ item.offer.name }} ({{ item.offer.id }})<br/>
                <strong>{{ item.offer.sku }}</strong> / {{ item.offer.barcodes }}
              </span>
              <span v-if="!item.offer?.id">
                {{ item.offer?.name }}
              </span>
            </q-item-section>

            <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="item.quantity"
                :label="$t('Quantity')"
                @update:model-value="updateItem(item.sku,'quantity', $event)"
            />

            <q-item-section style="max-width: 80px" class="q-pl-sm">
              <q-btn
                  color="negative"
                  size="sm"
                  :label="$t('Delete')"
                  @click="handleDelete(item.sku )"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <q-card-section class="text-center" v-if="model.length > 0">
        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Load')"
            @click="load"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Save')"
            @click="save"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Close')"
            @click="close"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
  <offers-search-modal ref="offersSearchModal"/>
</template>

<script>

import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'ImportProductsTextAreaModal',
  emits: ['submit'],
  data() {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      model: [],
      shop: null,
      isLoading: false,
      progressValue: 0,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      rawModel: '',
    }
  },
  computed: {},
  methods: {
    updateModel(value) {
      this.rawModel = value

      const lines = value.split('\n')
      const result = []

      for (const line of lines) {
        const parts = line.split(' ')
        const sku = parts[0]
        const rootMatch = sku.match(/[\d-\+]+/)
        const quantity = parseInt(parts[1])

        let rootValue = null
        if (rootMatch) {
          rootValue = rootMatch[0] // Extract numbers and hyphens from the first column
        }

        result.push({ sku, rootValue, quantity, offer: null })
      }

      this.model = result
    },
    setProgress(progressValue) {
      this.progressValue = progressValue
    },
    updateItem(item, field, value) {
      this.model.map(e => {
        if (e.sku === item) {
          e[field] = value
        }

        return e
      })
    },
    handleDelete(item) {
      this.model = this.model.filter(e => e.sku !== item)
    },
    close() {
      this.isOpen = false
    },
    open(shop) {
      this.shop = shop
      this.isOpen = true
    },
    async processItems(items) {
      this.isLoading = true
      let index = 0
      const total = items.length
      // Assuming findItems is a method of the component
      for (const item of items) {
        this.setProgress((index / total).toFixed(2))
        index++
        await this.findItems(item)
      }

      this.isLoading = false
    },
    findItems(item) {
      this.pagination = {}
      const query = buildQuery(this.pagination)

      query.filter = [
        {
          type: 'eq',
          alias: 'i',
          field: 'state',
          value: 'normal'
        },
        {
          type: 'gte',
          field: 'items',
          value: 1
        }
      ]

      item.offer = {
        name: 'Loading...'
      }

      query.filter.push(
          {
            type: 'orx',
            conditions: [
              {
                field: 'doc::text',
                type: 'ilike',
                value: item.rootValue + `%`
              },
              {
                field: 'doc::text',
                type: 'ilike',
                value: `%` + item.rootValue
              },
              {
                field: 'doc::text',
                type: 'ilike',
                value: `%` + item.rootValue + `%`
              }
            ],
            where: 'and'
          }
      )

      return this.$service.offer.getAll(query)
          .then(({ page, totalItems, items }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            if (items && items.length > 0) {
              return this.handleLoadOffer(items[0], item)
            } else {
              item.state = 'error'

              item.offer.name = '-'
            }
          })
    },
    handleLoadOffer(offer, item) {
      item.offer = offer

      return Promise.resolve()
    },
    afterProcessItems() {
      this.isOpen = false

      const offers = this.model.filter(item => item.offer && item.offer?.id).map(item => ({
        count: item.quantity,
        _embedded: { productOffer: item.offer },
      }))

      this.$emit('submit', { products: offers })
    },
    load() {
      this.processItems(this.model)
    },
    save() {
      this.afterProcessItems()
    },
    onImageLoadFailure(e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    }
  }
}
</script>
