<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 70vw;" class="q-pa-none">
      <q-card-section class="row">

        <div class="text-h6 text-center">
          {{ $t("Where?") }}
        </div>


        <q-space />

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>


      <q-card-section>
        <q-table
            class="my-q-table"
            row-key="id"
            :rows="storageItems"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="storageItemsLoading"
            binary-state-sort
            @request="onRequest"
        >

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
                @click="handleClick(props)"
            >
              <q-td
                  key="id"
                  :props="props"
                  auto-width
              >
                  <strong>{{props.row.id}}</strong>
              </q-td>

              <q-td
                  key="state"
                  :props="props"
                  auto-width
              >
                  <super-dropdown :model="schema(props.row)" />
              </q-td>

              <q-td
                  key="type"
                  :props="props"
                  auto-width
              >
                  {{props.row.type}}
              </q-td>

              <q-td
                  key="productOffer"
                  :props="props"
                  auto-width
              >
                  {{props.row._embedded?.productOffer?.name}}
              </q-td>

              <q-td
                  key="warehouse"
                  :props="props"
                  auto-width
              >
                  {{props.row._embedded.warehouse.name}}
              </q-td>

              <q-td
                  key="reserve"
                  :props="props"
                  auto-width
              >
                  {{props.row._embedded.reserve ? props.row._embedded.reserve.id : '-'}}
              </q-td>

              <q-td
                  key="place"
                  :props="props"
                  auto-width
              >
                <span v-if="props.row._embedded?.place">
                  <new-picking-place
                      :data="props.row._embedded.place"
                      :type="props.row._embedded.place.type"
                      badge
                  />
                </span>
              </q-td>

              <q-td
                  key="sku"
                  :props="props"
                  auto-width
              >
                  {{props.row.sku}}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {buildQuery} from "@/apps/app/utils/query-utils";
import SuperDropdown from "@/apps/app/components/super-dropdown/SuperDropdown.vue";
import NewPickingPlace from "@/apps/app/components/picking/new-picking-place.vue";

export default {
  name: 'OrderAllocationModal',
  emits:['submit','change'],
  components: {
    NewPickingPlace, SuperDropdown
  },
  data () {
    return {
      isOpen: false,
      orderProduct: null,
      order: null,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      states: [
        { id: 'normal', title: this.$t('Normal') },
        { id: 'delivered', title: this.$t('Delivered') },
        { id: 'blocked', title: this.$t('Blocked') },
        { id: 'booked', title: this.$t('Booked') },
        { id: 'deleted', title: this.$t('Deleted') },
        { id: 'new', title: this.$t('New') },
        { id: 'returned', title: this.$t('Returned') },
        { id: 'shipped', title: this.$t('Shipped') },
        { id: 'expected', title: this.$t('Expected') },
        { id: 'defected', title: this.$t('Defected') }
      ],
      itemStates: [
        {
          buttons: [
            { id: 'normal', title: 'Normal', transitions: [], color: 'success' },
            { id: 'delivered', title: 'Delivered', transitions: [], color: 'positive' },
            { id: 'blocked', title: 'Blocked', transitions: [], color: 'danger' },
            { id: 'booked', title: 'Booked', transitions: [], color: 'danger' },
            { id: 'deleted', title: 'Deleted', transitions: [], color: 'danger' },
            { id: 'new', title: 'New', transitions: [], color: 'grey-3'},
            { id: 'returned', title: 'Returned', transitions: [], color: 'warning'},
            { id: 'shipped' , title: 'Shipped', transitions: [], color: 'green text-white'},
            { id: 'expected', title: 'Expected' , transitions: [], color: 'green text-white'},
            { id: 'defected', title: 'Defected', transitions: [], color : 'dark text-white'}
          ]
        }
      ],
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'center'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Product offer'),
          name: 'productOffer',
          align: 'left'
        },
        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'center'
        },
        {
          label: this.$t('Reserve'),
          name: 'reserve',
          align: 'left'
        },
        {
          label: this.$t('Place'),
          name: 'place',
          align: 'center'
        },
        {
          label: this.$t('Sku'),
          name: 'sku',
          align: 'center'
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'totalStorageItemsNumber',
      'storageItemsLoading',
      'storageItems'
    ]),

  },
  methods: {
    ...mapActions([
      'loadStorageItems'
    ]),
    ...mapMutations([
      'addWarningNotification'
    ]),
    schema (row) {
      const value = this.itemStates[0].buttons.find(({ id }) => id === row.state) || { id: 'normal', title: 'Active' }
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {

        },
        value,
        options: this.itemStates
      }

      return option
    },
    close () {
      this.order = null
      this.orderItem = null
      this.isOpen = false
    },
    open (order , orderProduct) {
      this.order = order
      this.orderProduct = orderProduct
      this.isOpen = true
      this.onRequest()
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
          ? '*'
          : ':*'
      }
      query.filter = []
      if (this.orderProduct?._embedded?.productOffer) {
          query.filter.push({ type: 'eq', field: 'productOffer', value: this.orderProduct._embedded.productOffer.id})
        if (this.order.reserves) {
          const reserve = this.order.reserves.filter(e=> e.offer === this.orderProduct._embedded.productOffer.id).map(e=>e.id)
          if (reserve) {
            query.filter.push({ type: 'in', field: 'reserve', values: reserve})
          }
        }
      }
      if(this.order && this.order._embedded && this.order._embedded.warehouse) {
        query.filter.push({ type: 'eq', field: 'warehouse', value: this.order._embedded.warehouse.id})
      }


      return this.loadStorageItems(query)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

        })
        .catch(err => {
          if (err.message === 'No warehouses found') {
            this.isNeededWarehouse = true
          }
        })
    },
  }
}

</script>