<template xmlns="http://www.w3.org/1999/html">
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
    <q-td
        auto-width
    >
      <q-checkbox
          size="xl"
          :model-value="!!items.find(({id}) => id === data.row.id)"
          color="amber-6"
          @click="handleCheck(data.row)"
      />
    </q-td>

    <q-td
        key="id"
        :props="data"
        auto-width
    >
      <strong>{{ data.row.id }}</strong>
    </q-td>

    <q-td
        key="state"
        :props="data"
        auto-width
    >
      <super-dropdown :model="schema"/>
    </q-td>

    <q-td
        key="type"
        :props="data"
        auto-width
    >
      {{ data.row.type }}
    </q-td>

    <q-td
        key="productOffer"
        :props="data"
        auto-width
    >
      {{ data.row._embedded?.productOffer?.name }}
    </q-td>

    <q-td
        key="warehouse"
        :props="data"
        auto-width
    >
      {{ data.row._embedded.warehouse.name }}
    </q-td>

    <q-td
        key="reserve"
        :props="data"
        auto-width
    >
      {{ data.row._embedded.reserve ? data.row._embedded.reserve.id : '-' }}
    </q-td>

    <q-td
        key="place"
        :props="data"
        auto-width
    >
      <span v-if="data.row._embedded?.place">
        <new-picking-place
            :data="data.row._embedded.place"
            :type="data.row._embedded.place.type"
            badge
        />
      </span>
    </q-td>

    <q-td
        key="sku"
        :props="data"
        auto-width
    >
      {{ data.row.sku }}
    </q-td>

    <q-td
        key="acceptanceItem"
        :props="data"
        v-if="data.row._embedded?.acceptanceItem"
        auto-width>
      {{ 'ID: ' + data.row._embedded.acceptanceItem.id }} <br/>
      {{ 'TN: ' + data.row._embedded.acceptanceItem.trackingNumber }} <br />
      {{ 'QR: ' + data.row._embedded.acceptanceItem.quantityReal }}
    </q-td>

    <q-td
        key="batch"
        :props="data"
        auto-width
    >
      {{ data.row.batch ? data.row.batch : '-' }}
    </q-td>

  </q-tr>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown.vue'
import NewPickingPlace from '@/apps/app/components/picking/new-picking-place.vue'

export default {
  name: 'OrderItemsRow',
  emits: ['check', 'dblclick'],
  components: {
    NewPickingPlace,
    SuperDropdown
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      itemStates: [
        {
          buttons: [
            { id: 'normal', title: 'Normal', transitions: [], color: 'success' },
            { id: 'delivered', title: 'Delivered', transitions: [], color: 'positive' },
            { id: 'blocked', title: 'Blocked', transitions: [], color: 'danger' },
            { id: 'booked', title: 'Booked', transitions: [], color: 'danger' },
            { id: 'deleted', title: 'Deleted', transitions: [], color: 'danger' },
            { id: 'new', title: 'New', transitions: [], color: 'grey-3' },
            { id: 'returned', title: 'Returned', transitions: [], color: 'warning' },
            { id: 'shipped', title: 'Shipped', transitions: [], color: 'green text-white' },
            { id: 'expected', title: 'Expected', transitions: [], color: 'green text-white' },
            { id: 'defected', title: 'Defected', transitions: [], color: 'dark text-white' }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
    ]),
    schema () {
      const value = this.itemStates[0].buttons.find(({ id }) => id === this.data.row.state) || {
        id: 'normal',
        title: 'Active'
      }
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {

        },
        value,
        options: this.itemStates
      }

      return option
    }
  },
  methods: {
    ...mapMutations([]),
    onRowDblClick () {
      this.$emit('dblclick', this.data.row)
    },
    handleCheck () {
      this.$emit('check', this.data.row)
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
  }
}
</script>