<template>
  <q-card>
    <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
      <q-legend :label="$t('Delivery Requests')" />

      <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
      />

      <q-space />

      <q-btn
          v-if="!hideActions"
          :color="filters.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="toggleFilter"
      />

      <q-btn
          v-if="!hideActions"
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
      />

      <q-btn
          v-if="!hideActions || options.addButton"
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="createNewDeliveryRequest"
      />
    </q-card-section>

    <filter-collapse
        :is-open="isOpen"
        :options="{
        defaultFilter: filters,
        fields: activatedFields,
        values: {
          states: statuses
        },
        style: {
          noGroups: true
        }
      }"
        @submit="handleFiltersSubmit"
        @close="toggleFilter"
    />

    <q-card-section class="q-ma-none q-pa-none">
      <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="deliveryRequests"
          :columns="columns"
          v-model:pagination="pagination"
          :loading='deliveryRequestsLoading'
          :filter="filter"
          :table-header-class="$q.dark.isActive ? '' : (options.style && options.style.header) || ''"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
              showing
              color="primary"
          />
        </template>

        <template v-slot:body="props">
          <delivery-request-row-table
              :data="props"
              :row="props.row"
              :column="props.column"
              @dbclick="onRowClick"
          />
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'
import DeliveryRequestRowTable from "../../components/delivery-request-row-table/DeliveryRequestRowTable";

// Mixins
import TableMixin from '../../components/global/TableMixin'


// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'DeliveryRequestsTable',
  components: {
    Search,
    FilterCollapse,
    DeliveryRequestRowTable
  },
  mixins: [
    TableMixin
  ],
  props: {
    options: {
      type: Object,
      default () {
        return {
          filters: []
        }
      }
    },
    hideActions: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isOpen: false,
      stateColors: {
        active: 'teal',
        normal: 'success',
        blocked: 'dark'
      },
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Customer'),
          name: 'customer',
          align: 'left'
        },
        {
          label: this.$t('Sender'),
          name: 'sender',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Shipping'),
          name: 'shipping',
          align: 'left'
        }
      ],
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'id',
        'extId',
        'clientExtId',
        'trackingNumber',
        'shippedByDocument',
        'createdByDocument',
        'recipient',
        'recipientPhone',
        'weight.from',
        'weight.to',
        'retailPrice.from',
        'retailPrice.to',
        'estimatedCost.from',
        'estimatedCost.to',
        'payment.from',
        'payment.to',
        'shipmentDate.from',
        'shipmentDate.to',
        'deliveryDate.from',
        'deliveryDate.to',
        'pickUpDate.from',
        'pickUpDate.to',
        'created.from',
        'created.to',
        'deliveryService',
        'rate',
        'servicePoint',
        'integration',
        'paymentState',
        'state',
        'sender'
      ],
      filters: [
        { type: 'eq', field: 'state', value: 'active' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isClient',
      'appOptions',
      'order',
      'deliveryRequests',
      'deliveryRequestsItemsNumber',
      'deliveryRequestsLoading',
      'deliveryRequestsPage'
    ]),
    filterBtnText () {
      return this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadDeliveryRequests'
    ]),
    ...mapMutations([
      'setDeliveryRequest',
      'interceptItemsLoading',
      'setDeliveryRequestFromList',
      'setDeliveryRequests'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      this.filters = filter
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilter () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: { page: 1, rowsPerPage: 5 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query['order-by'] = [{type: 'field', field: 'created', direction: 'desc'}]
      query.filter = [{type: 'eq', field: 'order', value: this.order.id}]

      return this.loadDeliveryRequests(query)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
    },
    createNewDeliveryRequest () {
      this.$router.push('/outbound/shipments/entity/')
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRowClick (item) {
      this.setDeliveryRequest(item)
      this.$router.push('/outbound/shipments/entity/' + item.id)
    },
  }
}
</script>
